/* Ventana flotante */
.pop-out-window {
    position: absolute;
    background-color: #121418;
    border: 2px solid #6e44ff;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    resize: both; /* Habilita el redimensionamiento */
    min-width: 300px;
    min-height: 200px;
    padding: 0; /* Elimina cualquier padding */
  }
  
  /* Header de la ventana flotante */
  .pop-out-header {
    background-color: #6e44ff;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  /* Contenido de la ventana flotante */
  .pop-out-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    padding: 0; /* Elimina padding */
    margin: 0; /* Elimina margenes */
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  /* Estilos del iframe */
  .pop-out-content iframe {
    width: 100%;
    height: 100%;
    border: none; /* Elimina cualquier borde del iframe */
    display: block; /* Asegura que ocupe todo el espacio */
  }
  
  /* Botón de cierre */
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Manejar el redimensionamiento */
  .resize-handle {
    width: 15px;
    height: 15px;
    background-color: #6e44ff;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
    border-bottom-right-radius: 10px;
  }
  