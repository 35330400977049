/* src/App.css */
.app {
  background-color: #080808;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-grow: 1;
  transition: margin-left 0.3s ease; /* Transición suave para mover el contenido */
}

.content {
  flex-grow: 1;
  padding: 20px;
}

/* Ajustes cuando el sidebar está expandido */
.app.sidebar-expanded .main-content {
  margin-left: 250px; /* Mueve el contenido cuando el sidebar está expandido */
}

.app.sidebar-expanded .app-header {
  margin-left: 250px; /* Mueve el header también */
}

/* Ajustes cuando el sidebar está colapsado */
.app.sidebar-collapsed .main-content {
  margin-left: 60px; /* Mueve el contenido cuando el sidebar está colapsado */
}

.app.sidebar-collapsed .app-header {
  margin-left: 60px; /* Mueve el header cuando el sidebar está colapsado */
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .main-content, .app-header {
    margin-left: 0 !important; /* En móviles, el sidebar no afecta el layout */
  }

  .content {
    padding-bottom: 60px; /* Espacio para el menú inferior */
  }
}
