.balance-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .balance-info {
    display: flex;
    align-items: center;
    background-color: #080808;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
  }
  
  .balance-info:hover {
    border: 1px solid #6e44ff;
    box-shadow: 0 0 10px rgba(110, 68, 255, 0.5);
  }
  
  .crypto-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .arrow-icon {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }
  
  .wallet-btn {
    display: flex;
    align-items: center;
    background-color: #6e44ff;
    padding: 8px 16px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 14px;
  }
  
  .wallet-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  
  /* Ajusta el ancho del dropdown para que se ajuste al ancho del Balance Container y el botón Wallet */
  .crypto-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    background-color: #121418;
    padding: 15px;
    border-radius: 10px;
    width: calc(100% - 30px); /* Suma el ancho total del balance container y del botón de wallet */
    z-index: 1000;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .search-input {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    background-color: #1e1e1e;
    color: white;
    border: none;
    margin-bottom: 15px;
  }
  
  .crypto-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 250px;
    overflow-y: auto;
  }
  
  .crypto-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .crypto-list li:hover {
    background-color: #1e1e1e;
  }
  
  .crypto-list li .left-section {
    display: flex;
    align-items: center;
  }
  
  .crypto-list li .left-section img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .crypto-list li .right-section {
    text-align: right;
    color: white;
  }
  
  .display-fiat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 10px 0;
    color: white;
    border-top: 1px solid #1e1e1e;
  }
  
  .display-fiat span {
    font-size: 14px;
  }
  
  .display-fiat input[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #6e44ff;
  }
  