.app-header {
  position: fixed; /* Fija el header en la parte superior de la pantalla */
  top: 0;
  left: 0;
  width: 100%; /* Ocupa todo el ancho de la página */
  background-color: #080808;
  padding: 10px 20px; /* Padding extra para evitar que el sidebar lo tape */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-bottom: 2px solid #121418;
  z-index: 1000; /* Asegura que el header esté por encima de otros elementos */
}

.logo {
  width: 100px;
  height: auto;
  margin-right: 10px;
  margin-left: 20px; /* Ajuste para mover el logo más a la derecha */
}

.header-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.balance-container {
  display: flex;
  align-items: center;
  position: relative;
}

.wallet-btn {
  display: flex;
  align-items: center;
  background-color: #6e44ff;
  padding: 8px 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.wallet-btn img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

/* Botones de Login y Register */
.auth-btn {
  padding: 8px 16px;
  background-color: #6e44ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Espacio entre botones */
  transition: background-color 0.3s ease;
}

.auth-btn:hover {
  background-color: #5432cc;
}

.auth-buttons-container {
  display: flex;
  align-items: center;
}

/* Asegura que el contenido no quede oculto debajo del header */
body {
  padding-top: 60px; /* Ajusta el espacio debajo del header */
}

.main-content {
  margin-top: 60px; /* Asegura que el contenido principal esté visible y no quede cubierto por el header */
}

/* Para dispositivos móviles, hacemos el logo más pequeño */
@media (max-width: 768px) {
  .logo {
    width: 50px; /* Ajustamos el tamaño del logo para dispositivos móviles */
    margin-left: 10px; /* Ajusta el margen a la izquierda si es necesario */
  }
  
  .auth-btn {
    padding: 6px 12px; /* Reducir el tamaño de los botones en móviles */
    font-size: 12px;
  }
}
