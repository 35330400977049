.all-slots-page {
    padding: 20px;
  }
  
  .slots-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
  
  .filters {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-bar {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #2c2f33;
    color: #fff;
    width: 200px;
  }
  
  .filter, .sort {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #2c2f33;
    color: #fff;
  }
  
  .slots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .slot-card {
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    transition: transform 0.3s;
  }
  
  .slot-card:hover {
    transform: scale(1.05);
  }
  
  .slot-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .slot-info {
    margin-top: 10px;
  }
  
  .provider {
    font-size: 12px;
    color: #aaa;
  }
  
  .slot-name {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  